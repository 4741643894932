import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Book notes: `}<em parentName="h1">{`Functional-Light JavaScript`}</em></h1>
    <p>{`These are the raw notes that I took while reading `}<em parentName="p">{`Functional-Light JavaScript`}</em>{` by Kyle Simpson.`}</p>
    <h2>{`JavaScript implementations of common functions used in functional programming`}</h2>
    <hr></hr>
    <h3>{`Unary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function unary(fn) {
  return function onlyOneArg(arg) {
    return fn(arg);
  };
}
`}</code></pre>
    <hr></hr>
    <h3>{`Identity`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function identity(v) {
  return v;
}
`}</code></pre>
    <p>{`Can act as a truthy check when used as a predicate since JavaScript will coerce the value to `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`identity('anything') ? doSomethingTruthy() : doSomethingFalsey();
`}</code></pre>
    <hr></hr>
    <h3>{`Constant`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function constant(v) {
  return function value() {
    return v;
  };
}
`}</code></pre>
    <p>{`Useful when you need a function to return an unchanging value.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`p1.then(foo)
  .then(constant(unchangingVal))
  .then(bar);
`}</code></pre>
    <hr></hr>
    <h3>{`Apply`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function spreadArgs(fn) {
  return function spreadFn(argsArr) {
    return fn(...argsArr);
  };
}
`}</code></pre>
    <p>{`Useful to transform an array argument into individual args.`}</p>
    <hr></hr>
    <h3>{`Unapply`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function gatherArgs(fn) {
  return function gatheredFn(...argsArr) {
    return fn(argsArr);
  };
}
`}</code></pre>
    <p>{`Useful to transform individual args into an array of args.`}</p>
    <hr></hr>
    <h3>{`Partial`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function partial(fn, ...presetArgs) {
  return function partiallyApplied(...laterArgs) {
    return fn(...presetArgs, ...laterArgs);
  };
}
`}</code></pre>
    <p>{`Reduces the arity of `}<inlineCode parentName="p">{`fn`}</inlineCode>{` by creating another function where some args are preset.`}</p>
    <p>{`Returns a function that, when invoked, will run `}<inlineCode parentName="p">{`fn`}</inlineCode>{` with `}<inlineCode parentName="p">{`presetArgs`}</inlineCode>{` and invokation args (`}<inlineCode parentName="p">{`laterArgs`}</inlineCode>{`).`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`[1, 2, 3, 4, 5].map(partial(add, 3)); // [4,5,6,7,8]
`}</code></pre>
    <hr></hr>
    <h3>{`Partial Right`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function partialRight(fn, ...presetArgs) {
  return function partiallyApplied(...laterArgs) {
    return fn(...laterArgs, ...presetArgs);
  };
}
`}</code></pre>
    <p>{`Useful when you want to partially apply args from the right instead of left.`}</p>
    <hr></hr>
    <h3>{`Reverse Args`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function reverseArgs(fn) {
  return function argsReversed(...args) {
    return fn(...args.reverse());
  };
}
`}</code></pre>
    <hr></hr>
    <h3>{`Currying`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function curry(fn, arity = fn.length) {
  return (function nextCurried(prevArgs) {
    return function curried(nextArg) {
      var args = [...prevArgs, nextArg];

      if (args.length >= arity) {
        return fn(...args);
      } else {
        return nextCurried(args);
      }
    };
  })([]);
}
`}</code></pre>
    <p>{`Currying unwinds a single higher-arity function into a series of chained unary functions. It's basically a special form of partial application where the arity is reduced to 1.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` If you use this implementation of `}<inlineCode parentName="p">{`curry(..)`}</inlineCode>{` with a function that doesn't have an accurate `}<inlineCode parentName="p">{`length`}</inlineCode>{` property, you'll need to pass the `}<inlineCode parentName="p">{`arity`}</inlineCode>{` (the second parameter of `}<inlineCode parentName="p">{`curry(..)`}</inlineCode>{`) to ensure `}<inlineCode parentName="p">{`curry(..)`}</inlineCode>{` works correctly. The `}<inlineCode parentName="p">{`length`}</inlineCode>{` property will be inaccurate if the function's parameter signature includes default parameter values, parameter destructuring, or is variadic with `}<inlineCode parentName="p">{`...args`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function looseCurry(fn, arity = fn.length) {
  return (function nextCurried(prevArgs) {
    return function curried(...nextArgs) {
      var args = [...prevArgs, ...nextArgs];

      if (args.length >= arity) {
        return fn(...args);
      } else {
        return nextCurried(args);
      }
    };
  })([]);
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var curriedSum = looseCurry(sum, 5);
curriedSum(1)(2, 3)(4, 5); // 15
`}</code></pre>
    <hr></hr>
    <h3>{`Currying/Partial Without Ordering Dependency (Named Props)`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function partialProps(fn, presetArgsObj) {
  return function partiallyApplied(laterArgsObj) {
    return fn(Object.assign({}, presetArgsObj, laterArgsObj));
  };
}

function curryProps(fn, arity = 1) {
  return (function nextCurried(prevArgsObj) {
    return function curried(nextArgObj = {}) {
      var [key] = Object.keys(nextArgObj);
      var allArgsObj = Object.assign({}, prevArgsObj, {
        [key]: nextArgObj[key],
      });

      if (Object.keys(allArgsObj).length >= arity) {
        return fn(allArgsObj);
      } else {
        return nextCurried(allArgsObj);
      }
    };
  })({});
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function foo({ x, y, z } = {}) {
  console.log(\`x:\${x} y:\${y} z:\${z}\`);
}

var f1 = curryProps(foo, 3);
var f2 = partialProps(foo, { y: 2 });

f1({ y: 2 })({ x: 1 })({ z: 3 });   // x:1 y:2 z:3
f2({ z: 3, x: 1 });                 // x:1 y:2 z:3
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` we can only take advantage of currying with named arguments if we have control over the signature of `}<inlineCode parentName="p">{`foo(..)`}</inlineCode>{` and define it to destructure its first parameter.`}</p>
    <hr></hr>
    <h3>{`Complement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function not(predicate) {
  return function negated(...args) {
    return !predicate(...args);
  };
}
`}</code></pre>
    <hr></hr>
    <h3>{`When`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function when(predicate, fn) {
  return function conditional(...args) {
    if (predicate(...args)) {
      return fn(...args);
    }
  };
}
`}</code></pre>
    <hr></hr>
    <h3>{`Compose`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function compose2(fn2, fn1) {
  return function composed(origValue) {
    return fn2(fn1(origValue));
  };
}
`}</code></pre>
    <p>{`Most typical FP libraries define their `}<inlineCode parentName="p">{`compose(..)`}</inlineCode>{` to work right-to-left in terms of ordering. The function calls are listed to match the order they are written in code manually or rather the order we encounter them when reading from left-to-right.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function compose(...fns) {
  return function composed(result) {
    // copy the array of functions
    var list = [...fns];

    while (list.length > 0) {
      // take the last function off the end of the list
      // and execute it
      result = list.pop()(result);
    }

    return result;
  };
}
`}</code></pre>
    <p>{`Alternate implementation using recursion:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function compose(...fns) {
  // pull off the last two arguments
  var [fn1, fn2, ...rest] = fns.reverse();

  var composedFn = function composed(...args) {
    return fn2(fn1(...args));
  };

  if (rest.length == 0) return composedFn;

  return compose(...rest.reverse(), composedFn);
}
`}</code></pre>
    <p>{`Alternate implementation using `}<inlineCode parentName="p">{`reduce`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function compose(...fns) {
  return fns.reverse().reduce(function reducer(fn1, fn2) {
    return function composed(...args) {
      return fn2(fn1(...args));
    };
  });
}
`}</code></pre>
    <hr></hr>
    <h3>{`List Operations`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var filter = curry((predicateFn, arr) => arr.filter(predicateFn));

var map = curry((mapperFn, arr) => arr.map(mapperFn));

var reduce = curry((reducerFn, initialValue, arr) =>
  arr.reduce(reducerFn, initialValue)
);
`}</code></pre>
    <p>{`You can create a generic "invoker" method to handle all 3:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var unboundMethod = (methodName, argCount = 2) =>
  curry((...args) => {
    var obj = args.pop();
    return obj[methodName](...args);
  }, argCount);

var filter = unboundMethod('filter', 2);
var map = unboundMethod('map', 2);
var reduce = unboundMethod('reduce', 3);
`}</code></pre>
    <hr></hr>
    <h2>{`Other Notes`}</h2>
    <p>{`Other notes on concepts and vocabulary relating to functional programming and/or JavaScript.`}</p>
    <h3>{`Point Free Style (AKA Tactic Programming)`}</h3>
    <p>{`Point = Parameter`}</p>
    <p>{`Point Free = Not explicitly passing a parameter to a unary function and letting the outer function implicitly pass the param to the inner function.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function double(x) {
  return x * 2;
}
[1, 2, 3, 4, 5].map(double); // [2,4,6,8,10]
`}</code></pre>
    <h3>{`Idempotence`}</h3>
    <p>{`From the mathematical point of view `}<em parentName="p">{`idempotence`}</em>{` means an operation whose output won't ever change after the first call if you feed that output back into the operation over and over again.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Math.abs(..)
Math.min(..)
Math.max(..)
Math.round(..)
Math.floor(..)
Math.ceil(..)
`}</code></pre>
    <p>{`The programming-oriented definition for `}<em parentName="p">{`idempotence`}</em>{` is similar, but less formal. Instead of requiring `}<inlineCode parentName="p">{`f(x) === f(f(x))`}</inlineCode>{`, this view of idempotence is just that `}<inlineCode parentName="p">{`f(x);`}</inlineCode>{` results in the same program behavior as `}<inlineCode parentName="p">{`f(x); f(x);`}</inlineCode>{`. In other words, the result of calling `}<inlineCode parentName="p">{`f(x)`}</inlineCode>{` subsequent times after the first call doesn't change anything.`}</p>
    <h3>{`Closure vs Objects`}</h3>
    <p>{`A closure associates a single function with a set of state whereas an object holding the same state can have any number of functions to operate on that state.`}</p>
    <h3>{`Reduce`}</h3>
    <p><strong parentName="p">{`Warning:`}</strong>{` In JavaScript, if there's not at least one value in the reduction (either in the array or specified as `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{`) an error is thrown. Be careful not to omit the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` if the list for the reduction could possibly be empty.`}</p>
    <h3>{`Beneficial Side Effects`}</h3>
    <p>{`Side effects may be useful for performance reasons like for caching.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var specialNumber = (function memoization() {
  var cache = [];

  return function specialNumber(n) {
    // if we've already calculated this special number,
    // skip the work and just return it from the cache
    if (cache[n] !== undefined) {
      return cache[n];
    }

    var x = 1,
      y = 1;

    for (let i = 1; i <= n; i++) {
      x += i % 2;
      y += i % 3;
    }

    cache[n] = (x * y) / (n + 1);

    return cache[n];
  };
})();
`}</code></pre>
    <p>{`The caching is useful here since passing a large number to `}<inlineCode parentName="p">{`specialNumber`}</inlineCode>{` can be costly.`}</p>
    <h3>{`Freezing JavaScript Objects`}</h3>
    <p>{`Notable `}<inlineCode parentName="p">{`Object.freeze`}</inlineCode>{` behavior:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var x = Object.freeze([2, 3, [4, 5]]);

// not allowed:
x[0] = 42;

// oops, still allowed:
x[2][0] = 42;
`}</code></pre>
    <h3>{`Vocabulary`}</h3>
    <p>{`A pure function has `}<em parentName="p">{`referential transparency`}</em>{` which is an assertion that a function call could be replaced by its output value and the overall program behavior wouldn't change.`}</p>
    <p><em parentName="p">{`Isomorphism`}</em>{` in JavaScript is when you have one set of JavaScript code that is converted to another set of JavaScript code and (importantly) you could convert from the latter back to the former if you wanted.`}</p>
    <p>{`An array is a `}<em parentName="p">{`functor`}</em>{` because it has a functor utility (`}<inlineCode parentName="p">{`map`}</inlineCode>{`) that can perform an operation (operator function) on each member resulting in a transformed data structure.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      